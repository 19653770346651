import { useRouter } from 'next/router';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { red, orange, green, grey } from '@material-ui/core/colors';
import { format, parseISO, formatDistanceToNow, isFuture } from 'date-fns';
import useNotifications from '../hooks/useNotifications';
import LoadingSpinner from './LoadingSpinner';

const statuses = {
  NOT_STARTED: {
    label: 'Not Started',
    color: red[800],
  },
  INCOMPLETE: {
    label: 'Incomplete',
    color: orange[900],
  },
  COMPLETED: {
    label: 'Completed',
    color: green[800],
  },
};

function getCompletionStatus({ subject, dataCaptureId }) {
  const hexScores = subject.hexScores.filter(
    (e) => e.areaId === subject.area.id && e.dataCaptureId === dataCaptureId
  );
  if (!hexScores.length) return statuses['NOT_STARTED'];
  if (
    hexScores.every((hex) =>
      Object.keys(hex)
        .filter((key) => key === 'score' || key.startsWith('statement'))
        .every((key) => hex[key] !== null)
    )
  )
    return statuses['COMPLETED'];
  return statuses['INCOMPLETE'];
}

function NotificationsEmptyState() {
  return (
    <div style={{ marginTop: 30, textAlign: 'center' }}>
      <ErrorOutlineIcon style={{ width: 50, height: 50 }} />
      <Typography component="h2" variant="h5" style={{ fontWeight: 900 }}>
        Your school hasn&apos;t sent any notifications
      </Typography>
      <Typography>
        When they do, those notifications will show up here.
      </Typography>
    </div>
  );
}

export default function Notifications() {
  const router = useRouter();
  const {
    data: notifications = [],
    isLoading,
    isError,
    error,
  } = useNotifications();

  if (isLoading) return <LoadingSpinner />;

  if (isError) return <span>Error: {error.message}</span>;

  if (!isLoading && !notifications.length) return <NotificationsEmptyState />;

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Capture</TableCell>
            <TableCell>Area</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Deadline</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications?.map((notification) => {
            const timeRemaining = formatDistanceToNow(
              new Date(notification.dataCapture.deadlineDate),
              { addSuffix: true }
            );
            const deadlineDate = format(
              parseISO(notification.dataCapture.deadlineDate),
              'dd/MM/yyyy'
            );
            const isActive = isFuture(
              new Date(notification.dataCapture.deadlineDate)
            );
            const { label, color } = getCompletionStatus(notification);
            const submissionLink = `/submission?captureId=${notification.dataCapture.id}&subjectId=${notification.subject.id}`;
            return (
              <TableRow key={notification.id}>
                <TableCell>{notification.dataCapture.title}</TableCell>
                <TableCell>{notification.subject.area.name}</TableCell>
                <TableCell>{notification.subject.name}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={label}
                    variant="outlined"
                    style={{ borderColor: color, color }}
                  />
                </TableCell>
                <TableCell>
                  {deadlineDate} ({timeRemaining})
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CreateIcon />}
                    onClick={() => router.push(submissionLink)}
                    disabled={!isActive}
                  >
                    Submit entry
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
