import NextLink from 'next/link';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import SchoolIcon from '@material-ui/icons/School';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssessmentIcon from '@material-ui/icons/Assessment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CompareIcon from '@material-ui/icons/Compare';
import { useAuth } from '../components/AuthProvider';
import Notifications from '../components/Notifications';
import { roles, hasRole, hasPermission, actions } from '../lib/permissions';

const quickActions = [
  {
    label: 'Manage Staff',
    icon: <PeopleAltIcon fontSize="large" />,
    href: '/users',
    role: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  },
  {
    label: 'Manage Trust Members',
    icon: <PeopleAltIcon fontSize="large" />,
    href: '/users/trust',
    role: [roles.TRUST_ADMIN, roles.TRUST_EXECUTIVE],
  },
  {
    label: 'Edit Hexes',
    icon: <EditIcon fontSize="large" />,
    href: '/hexes',
    role: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  },
  {
    label: 'Manage Tiers',
    icon: <BuildIcon fontSize="large" />,
    href: '/areas',
    role: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  },
  {
    label: 'Capture Summary',
    icon: <AssessmentIcon fontSize="large" />,
    href: '/summary',
    role: [
      roles.ADMIN,
      roles.TEACHER,
      roles.TRUST_USER,
      roles.TRUST_ADMIN,
      roles.TRUST_EXECUTIVE,
    ],
  },
  {
    label: 'Capture Comparison',
    icon: <CompareIcon fontSize="large" />,
    href: '/captures/compare',
    role: [
      roles.ADMIN,
      roles.TEACHER,
      roles.TRUST_USER,
      roles.TRUST_ADMIN,
      roles.TRUST_EXECUTIVE,
    ],
  },
  {
    label: 'Schools & Trusts',
    icon: <SchoolIcon fontSize="large" />,
    href: '/schools',
    role: [roles.SUPERADMIN],
  },
  {
    label: 'Change Password',
    icon: <VpnKeyIcon fontSize="large" />,
    href: '/settings',
    role: [roles.TEACHER],
  },
  {
    label: 'Trust Overview',
    icon: <VisibilityIcon fontSize="large" />,
    href: '/trusts/overview',
    role: [roles.TRUST_USER, roles.TRUST_ADMIN, roles.TRUST_EXECUTIVE],
  },
];

function ActionCard({ label, icon, href }) {
  return (
    <NextLink href={href} passHref>
      <Card variant="outlined" style={{ height: '100%', cursor: 'pointer' }}>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 25,
            userSelect: 'none',
          }}
        >
          {icon}
          <Typography
            component="h2"
            variant="h6"
            style={{ fontWeight: 900, marginTop: 10, textAlign: 'center' }}
          >
            {label}
          </Typography>
        </CardContent>
      </Card>
    </NextLink>
  );
}

export default function HomePage() {
  const { user } = useAuth();
  if (!user) return null;
  return (
    <div>
      <main>
        <Typography>
          You are logged in as <strong>{user.attributes.email}</strong>
        </Typography>
        <Typography
          component="h2"
          variant="h4"
          style={{ fontWeight: 900, marginTop: 20 }}
        >
          Quick Actions
        </Typography>
        <Divider style={{ marginBottom: 10 }} />
        <Grid container spacing={2}>
          {quickActions.map(({ label, icon, href, role }) => {
            if (!hasRole(user, role)) return null;
            return (
              <Grid item xs={6} sm={4} md={3} key={label}>
                <ActionCard label={label} icon={icon} href={href} />
              </Grid>
            );
          })}
        </Grid>
        {hasPermission(user, actions.VIEW_NOTIFICATIONS) && (
          <>
            <Typography
              component="h2"
              variant="h4"
              style={{ fontWeight: 900, marginTop: 20 }}
            >
              Recent Notifications
            </Typography>
            <Divider style={{ marginBottom: 10 }} />
            <Notifications />
          </>
        )}
      </main>
    </div>
  );
}
