import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  container: {
    margin: '30px 0',
    textAlign: 'center',
  },
});

export default function LoadingSpinner({ size = 100 }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress size={size} />
    </div>
  );
}
