import { useQuery } from 'react-query';
import { useAuth } from '../components/AuthProvider';
import { hasPermission, actions } from '../lib/permissions';

async function fetchNotifications() {
  const res = await fetch('/api/notifications');
  return res.json();
}

export default function useNotifications() {
  const { user } = useAuth();
  return useQuery('notifications', fetchNotifications, {
    enabled: !!user && hasPermission(user, actions.VIEW_NOTIFICATIONS),
  });
}
